export default {
	messages: {
		uk: {
			Отримайте: 'Отримайте',
			вигоди: 'вигоди',
			'на перевірки, для вас та ваших покупців': 'на перевірки, для вас та ваших покупців',
			'Вам доступна': 'Вам доступна',
			'на всі типи перевірок': 'на всі типи перевірок',
			вигода: 'вигода',
			'Тільки для': 'Тільки для',
			Детальніше: 'Детальніше',
			'Продавець потурбувався та активував для вас': 'Продавець потурбувався та активував для вас',
			'на будь-яку перевірку AUTO.RIA для всіх його авто': 'на будь-яку перевірку AUTO.RIA для всіх його авто',
		},
		ru: {
			Отримайте: 'Получите',
			вигоди: 'выгоды',
			'на перевірки, для вас та ваших покупців': 'на проверки для вас и ваших покупателей',
			'Вам доступна': 'Вам доступна',
			'на всі типи перевірок': 'на все типы проверок',
			вигода: 'выгода',
			'Тільки для': 'Только для',
			Детальніше: 'Подробнее',
			'Продавець потурбувався та активував для вас': 'Продавец позаботился и активировал для вас',
			'на будь-яку перевірку AUTO.RIA для всіх його авто': 'на любую проверку AUTO.RIA для всех его автомобилей',
		},
	}
};
