import { mapActions } from 'vuex';
import Vue from 'vue';
import axios from 'axios';
import storeModule from '../../store/congratulations';
import stepPublicationLogger from '../../mixins/stepPublicationLogger';
import templateEngine from '../../mixins/templateEngine';
import slonDataLogger from '../../mixins/slonDataLogger';
import { get } from '../../helpers/cookie';

export default {
  name: 'StepPublication',
  metaInfo() {
    return {
      title: `AUTO.RIA – ${this.$t('Автобазар №1. Купити і продати авто легко як ніколи')}`,
      meta: [
        {
          name: 'description',
          content: this.$t('AUTO.RIA — швидкий продаж та легка купівля нових і вживаних автомобілів. Автобазар, на якому продається 1 400 автомобілів щодня. Пошук по базі оголошень про продаж автомобілів. Каталоги автосалонів і СТО на Авторіа.'),
        },
        { property: 'og:site_name', content: 'AUTO.RIA' },
        { property: 'og:title', content: `AUTO.RIA – ${this.$t('Автобазар №1. Купити і продати авто легко як ніколи')}` },
        {
          property: 'og:description',
          content: this.$t('AUTO.RIA — швидкий продаж та легка купівля нових і вживаних автомобілів. Автобазар, на якому продається 1 400 автомобілів щодня. Пошук по базі оголошень про продаж автомобілів. Каталоги автосалонів і СТО на Авторіа.'),
        },
        { property: 'og:image', content: 'https://css.riastatic.com/images/og-placeholder.png' },
        { name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW' },
      ],
    };
  },
  data() {
    return {
      accountData: undefined,
      banners: undefined,
      advertisement: undefined,
      publicationBlocks: undefined,
      level: undefined,
      period: undefined,
      periodPublication: undefined,
      blockReload: '',
      publicationData: undefined,
      action: undefined,
      userType: undefined,
      showPublishedPopup: false,
      isForcePublish: false,
      lastClickedBtn: undefined,
    };
  },
  init({ store }) {
    store.registerModule('publication', storeModule);
  },
  mixins: [stepPublicationLogger, templateEngine, slonDataLogger],
  components: {
    account: () => import('./AccountBlock/index.vue'),
    auto: () => import('./AutoBlock/index.vue'),
    banners: () => import('./BannersBlock/index.vue'),
    'publications-blocks': () => import('./PublicationBlocks/index.vue'),
    'was-recently-published': () => import('./Popup/WasRecentlyPublished/index.vue'),
  },
  methods: {
    ...mapActions({
      fetchData: 'publication/fetchData',
      createPaymentWindow: 'publication/createPaymentWindow',
      createPaymentPrivat24: 'publication/createPaymentPrivat24',
      logPublicationStep: 'publication/logPublicationStep',
      startApplePaymentSession: 'others/startApplePaymentSession',
      makeOrder: 'others/makeOrder',
    }),
    reloadValue({ type, value }) {
      switch (type) {
        case 'level':
          this.level = value;
          break;
        case 'period':
          this.period = value;
          break;
        case 'periodPublication':
          this.periodPublication = value;
          break;
      }
    },
    getSourceId(blockName) {
      switch (blockName) {
        case 'easy':
          return 14;
        case 'fast':
          return 15;
        case 'turbo':
          return 16;
        case 'free':
          return 17;
      }
    },
    fillingFormData({
      level = 0, period = 0, publicationDays = 0, blockName = 'easy',
    }) {
      const formData = new FormData();
      formData.append('advertisementId', this.$route.params.autoId);
      formData.append('level', level);
      formData.append('period', period);
      formData.append('publicationDays', publicationDays);
      formData.append('publicationPeriod', this.action === 'archive-renew' ? 0 : 1);
      formData.append('device', this.isDesktop ? 'desktop' : 'mobile');
      formData.append('pageName', 'step3');
      formData.append('label', 1);
      formData.append('clearWall', 1);
      formData.append('restore', Number(this.action === 'archive-renew'));
      formData.append('sourceId', this.getSourceId(blockName));
      formData.append('userType', this.userType);
      if (period) {
        this.slonikLogs(576, {
          level,
          period,
          actionBlock: this.getSourceId(blockName),
        });
      }
      return formData;
    },
    applePaySubmit(blockData, buttonName, actionData) {
      const session = new ApplePaySession(1, actionData.data);

      // Відправка сертифікату Merchant Identity Certificate на бекенд (запит на валідацію)
      session.onvalidatemerchant = ({ validationURL }) => {
        const data = {
          validationURL,
          type: actionData.type,
          merchantIdentifier: actionData.data.merchantIdentifier,
        };

        this.startApplePaymentSession(data)
          .then(({ merchantSession }) => {
            session.completeMerchantValidation(merchantSession);
          })
          .catch(() => {
            session.abort();
          });
      };

      // Відправка запиту на оплату
      session.onpaymentauthorized = (event) => {
        const data = {
          services: actionData.services,
          paymentMethod: 'APay',
          paymentData: event.payment?.token?.paymentData || {},
          paymentTypeId: actionData?.paymentType,
          paymentCost: actionData?.sum,
        };

        this.makeOrder(data)
          .then((response) => {
            if (response?.orderId) {
              session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
              this.onSuccessPayment(response, blockData);
            } else {
              session.completePayment(window.ApplePaySession.STATUS_FAILURE);
            }
          })
          .catch((e) => {
            session.abort();
          });
      };

      session.begin();
    },
    onSuccessPayment(payload, blockData) {
      const billingId = payload?.billingId || payload?.orderId || payload?.freeOrderId;
      const paymentType = payload?.paymentType || payload?.payData?.invoiceData?.payment_type_id;

      this.slonikLogs(505, {
        actionBlock: this.getSourceId(blockData?.name),
        billingId,
        paymentType,
        serviceId: 237,
        paymentSum: this.publicationData?.basePrice,
      });

      if (blockData?.paymentValues?.topPeriod) {
        this.slonikLogs(505, {
          level: blockData?.paymentValues?.topLevel,
          top: blockData?.paymentValues?.topPeriod,
          actionBlock: this.getSourceId(blockData?.name),
          billingId,
          paymentType,
          serviceId: 206,
          paymentSum: this.publicationData?.basePrice,
        });
      }

      // @todo check analytics глянути чи всі параметри і шо необхідно для кроку привітання
      this.$router.push({
        path: `${this.langPrefix}/addauto/congratulations/${this.$route.params.autoId}`,
        query: {
          pubPrice: this.publicationData?.basePrice || 0,
          levelPrice: this.publicationData?.levelPrice || 0,
          advertisementId: this.$route.params.autoId,
          level: blockData?.paymentValues?.topLevel,
          period: blockData?.paymentValues?.topPeriod,
          publicationPeriod: 1,
          device: this.isDesktop ? 'desktop' : 'mobile',
          pageName: 'step3',
          sourceId: this.getSourceId(blockData.name),
          publish: this.action === 'publication' ? 1 : 0,
          republish: this.action === 'republication' ? 1 : 0,
          free: this.publicationData?.currentPrice === 0 ? 1 : 0,
          priceReason: this.publicationData?.reasonId,
          publicationDays: blockData?.paymentValues?.publicationDays,
          userType: this.userType,
          restore_from_archive: this.action === 'archive-renew' ? 1 : 0,
          reference: 'v2',
        },
      });
    },
    getPaymentData({ topLevel: level, topPeriod: period, publicationDays }, paymentMethod = '') {
      const data = {
        services: [],
      };

      if (paymentMethod) data.paymentMethod = paymentMethod;

      if (publicationDays) {
        data.services.push({
          name: 'publication',
          advertisementId: this.$route.params.autoId,
          publicationDays,
        });
      }

      if (level && period) {
        data.services.push({
          name: 'level',
          advertisementId: this.$route.params.autoId,
          level,
          period,
        });
      }

      return data;
    },
    async restoreAdvertisement() {
      try {
        await axios({
          method: 'POST',
          url: '/api/publications/public/restore',
          data: {
            advertisementId: [this.$route.params.autoId],
          },
        });
      } catch (e) {
        console.error('/api/publications/public/restore', e.message);
      }
    },
    async createOrder(blockData, buttonName, hasRenew) {
      const paymentData = this.getPaymentData({
        ...blockData?.paymentValues,
        ...(hasRenew && { publicationDays: 0 }),
      });

      if (!paymentData.services.length) {
        this.onSuccessPayment({}, blockData);
        return;
      }

      const billingWindow = this.isDesktop
        ? window.open('https://auto.ria.com/demo/partials/loader/wait.html', '_blank')
        : window;

      try {
        const orderResponse = await this.makeOrder(paymentData);

        // Платне оголошення
        if (orderResponse?.orderId) {
          let orderLink = orderResponse?.orderLink;
          let successData = orderResponse;

          if (buttonName === 'publicate_privat') {
            const privatResponse = await this.createPaymentPrivat24({
              orderId: orderResponse?.orderId,
            });

            orderLink = privatResponse.paymentUrl;
            successData = {
              ...successData,
              paymentType: 115,
            };
          }

          billingWindow.location.href = orderLink;
          this.onSuccessPayment(successData, blockData);
          // Безкоштовне оголошення
        } else if (orderResponse?.freeOrderId) {
          this.onSuccessPayment(orderResponse, blockData);
          if (this.isDesktop) billingWindow.window.close();
          // Будь-яка помилка
        } else if (this.isDesktop) {
          billingWindow.window.close();
        }
      } catch (error) {
        console.error('Refill makeOrder error', error.message);
        if (this.isDesktop) billingWindow.window.close();

        if (error.status === 400 && error.data?.code === 42) {
          this.showPublishedPopup = true;
          this.lastClickedBtn = { blockData, buttonName };
        }
      }
    },
    async onSubmit({ blockData, button }) {
      const { name: buttonName, actionData } = button;
      const data = this.fillingFormData({
        level: blockData?.paymentValues?.topLevel,
        period: blockData?.paymentValues?.topPeriod,
        publicationDays: blockData?.paymentValues?.publicationDays,
        blockName: blockData?.name,
      });

      /**
       * для перевірки формдати
       *       for (var pair of data.entries()) {
       *         console.log(pair[0]+ ', ' + pair[1]);
       *       }
       */

      // https://auto-ria.atlassian.net/browse/VCWEB-5330
      if (this.action === 'archive-renew') {
        data.delete('level');
        data.delete('period');
      }

      this.slonikLogs(697, {
        pointAction: `${blockData?.name}_${buttonName}`,
        actionBlock: this.getSourceId(blockData?.name),
      });

      if (buttonName === 'publicate_a-pay') {
        this.applePaySubmit(blockData, buttonName, actionData);
      } else {
        const hasRenew = this.action === 'archive-renew';

        if (hasRenew) {
          await this.restoreAdvertisement();
        }

        const isNewApiBilling = true;

        if (isNewApiBilling) {
          await this.createOrder(blockData, buttonName, hasRenew);
        } else {
          const payload = await this.createPaymentWindow({
            advertisementId: this.$route.params.autoId,
            requestData: data,
            buttonName,
            forceAction: this.isForcePublish,
          });
          const { status, data: respData } = payload?.response || {};

          if (status === 400 && respData.code === 42 && !this.isForcePublish) {
            this.showPublishedPopup = true;
            this.lastClickedBtn = { blockData, buttonName };

            return;
          }

          this.onSuccessPayment(payload, blockData);
        }
      }
    },
    slonikLogs(eventId, payload) {
      this.sendEventToSlonik(eventId, { actionType: this.action === 'publication' ? 1 : 2, ...payload });
    },
    submitForcePublishing() {
      this.showPublishedPopup = false;
      this.isForcePublish = true;
      this.onSubmit(this.lastClickedBtn);
    },
    publicationStepLogger() {
      try {
        const params = {
          auto_id: this.$route.params.autoId,
          web_id: this.getWebId(),
          device: this.isDesktop ? 1 : 2,
          action: this.action === 'publication' ? 1 : 2,
          version: 2,
          previous_url: document.referrer,
        };
        this.logPublicationStep(params);
      } catch (err) {
        console.error(`Error in publicationStepLogger: ${err.toString()}`);
      }
    },
  },
  mounted() {
    this.publicationStepLogger();
    const canMakeAPay = Boolean(window?.ApplePaySession?.canMakePayments());

    // когда нужно перерисовать основной блок
    this.$root.$on('reloadBlock', ({ blockType, type, value }) => {
      this.blockReload = blockType;
      if (!Number.isNaN(value)) {
        this.reloadValue({ type, value });
      }
      const { publicationBlocks } = this;
      let contentIndex;
      const block = publicationBlocks.content.find(({ name }, index) => {
        if (name === blockType) {
          contentIndex = index;
          return true;
        }
        return false;
      });
      this.fetchData({
        advertisementId: this.$route.params.autoId,
        queryType: 'publicationBlocks',
        queryParams: {
          blockName: blockType,
          level: (
            this.level !== null && this.level !== undefined) ? this.level : block?.paymentValues?.topLevel,
          period: this.period,
          periodPublication: type === 'periodPublication' ? Number(value) : this.periodPublication,
          isMobileWeb: this.isMobile,
          ...canMakeAPay && { withAPay: 1 },
        },
      }).then((data) => {
        Vue.set(publicationBlocks.content, contentIndex, data);
        this.blockReload = '';
      });
    });

    this.fetchData({
      advertisementId: this.$route.params.autoId,
      queryType: 'allData',
      queryParams: {
        isMobileWeb: this.isMobile,
        ...canMakeAPay && { withAPay: 1 },
      },
    }).then((data) => {
      const {
        accountData, banners, advertisement, publicationBlocks,
      } = data?.templates;
      const { publicationData, action, userType } = data?.values;
      this.accountData = accountData;
      this.banners = banners;
      this.advertisement = advertisement;
      this.publicationBlocks = publicationBlocks;
      this.publicationData = publicationData;
      this.action = action;
      this.userType = userType;
      this.slonikLogs(307);
    }).catch(() => {
      location.href = document.referrer ? document.referrer : `${this.langPrefix}/add_auto.html`;
    });
    this.slonikLogs(697);
  },
  i18n: {
    messages: {
      uk: {
        'Автобазар №1. Купити і продати авто легко як ніколи': 'Автобазар №1. Купити і продати авто легко як ніколи',
        'AUTO.RIA — швидкий продаж та легка купівля нових і вживаних автомобілів. Автобазар, на якому продається 1 400 автомобілів щодня. Пошук по базі оголошень про продаж автомобілів. Каталоги автосалонів і СТО на Авторіа.': 'AUTO.RIA — швидкий продаж та легка купівля нових і вживаних автомобілів. Автобазар, на якому продається 1 400 автомобілів щодня. Пошук по базі оголошень про продаж автомобілів. Каталоги автосалонів і СТО на Авторіа.',
        'Після оплати ваше оголошення буде опубліковано': 'Після оплати ваше оголошення буде опубліковано',
      },
      ru: {
        'Автобазар №1. Купити і продати авто легко як ніколи': 'Автобазар №1. Купить и продать авто легко как никогда',
        'AUTO.RIA — швидкий продаж та легка купівля нових і вживаних автомобілів. Автобазар, на якому продається 1 400 автомобілів щодня. Пошук по базі оголошень про продаж автомобілів. Каталоги автосалонів і СТО на Авторіа.': 'AUTO.RIA — быстрая продажа и легкая покупка новых и б/у автомобилей. Автобазар, на котором продается 1400 автомобилей каждый день. Поиск по базе объявлений о продаже автомобилей. Каталоги автосалонов и СТО на Авториа.',
        'Після оплати ваше оголошення буде опубліковано': 'После оплаты ваше объявление будет опубликовано',
      },
    },
  },
};
