import { mapGetters } from 'vuex';
import betweenDate from '../../../../../mixins/betweenDate';
import slonDataLogger from '../../../../../mixins/slonDataLogger';
import popupBenefitTops60 from '../Popups/PopupBenefitTops60/index.vue';
import CabinetPopUpBlockId103 from '../Popups/CabinetPopUpBlockId103/index.vue';
import popupCarOwner from '../Popups/PopupCarOwner/index.vue';
import popupFrontlineRegions from '../Popups/PopupFrontlineRegions/index.vue';
import PopupElectricCars from '../Popups/PopupElectricCars/index.vue';
import PopupTops23 from '../Popups/PopupTops23/index.vue';
import PopupTopsC2C from '../Popups/PopupTopsC2C/index.vue';
import PopupTopsSMB from '../Popups/PopupTopsSMB/index.vue';
import PopupTopsB2C1 from '../Popups/PopupTopsB2C1/index.vue';
import PopupTops28 from '../Popups/PopupTops28/index.vue';
import SelectPackages from '../Popups/SelectPackages/index.vue';
import PopupNationalHolidaySellers30 from '../Popups/PopupNationalHolidaySellers30/index.vue';
import PopupNationalHolidaySellers50 from '../Popups/PopupNationalHolidaySellers50/index.vue';
import PopupTopRepublicationSmb from '../Popups/PopupTopRepublicationSmb/index.vue';
import PopupTopRepublicationB2c from '../Popups/PopupTopRepublicationB2c/index.vue';
import PopupFebruary29 from '../Popups/PopupFebruary29/index.vue';
import PopupFiveCarsWithVIN from '../Popups/PopupFiveCarsWithVIN/index.vue';
import CabinetPopUpBlockId135 from '../Popups/CabinetPopUpBlockId135/index.vue';
import CabinetPopUpBlockId136 from '../Popups/CabinetPopUpBlockId136/index.vue';
import CabinetPopUpBlockId137 from '../Popups/CabinetPopUpBlockId137/index.vue';
import CabinetPopUpBlockId138 from '../Popups/CabinetPopUpBlockId138/index.vue';
import CabinetPopUpBlockId139 from '../Popups/CabinetPopUpBlockId139/index.vue';
import CabinetPopUpBlockId149 from '../Popups/CabinetPopUpBlockId149/index.vue';
import CabinetPopUpBlockId153 from '../Popups/CabinetPopUpBlockId153/index.vue';
import CabinetPopUpBlockId154 from '../Popups/CabinetPopUpBlockId154/index.vue';
import CabinetPopUpBlockId157 from '../Popups/CabinetPopUpBlockId157/index.vue';
import CabinetPopUpBlockId158 from '../Popups/CabinetPopUpBlockId158/index.vue';
import CabinetPopUpBlockId161 from '../Popups/CabinetPopUpBlockId161/index.vue';
import CabinetPopUpBlockId162 from '../Popups/CabinetPopUpBlockId162/index.vue';
import CabinetPopUpBlockId163 from '../Popups/CabinetPopUpBlockId163/index.vue';
import CabinetPopUpBlockId164 from '../Popups/CabinetPopUpBlockId164/index.vue';
import CabinetPopUpBlockId165 from '../Popups/CabinetPopUpBlockId165/index.vue';
import CabinetPopUpBlockId166 from '../Popups/CabinetPopUpBlockId166/index.vue';
import CabinetPopUpBlockId171 from '../Popups/CabinetPopUpBlockId171/index.vue';
import CabinetPopUpBlockId172 from '../Popups/CabinetPopUpBlockId172/index.vue';
import CabinetPopUpBlockId174 from '../Popups/CabinetPopUpBlockId174/index.vue';
import CabinetPopUpBlockId177 from '../Popups/CabinetPopUpBlockId177/index.vue';
import CabinetPopUpBlockId178 from '../Popups/CabinetPopUpBlockId178/index.vue';
import CabinetPopUpBlockId183 from '../Popups/CabinetPopUpBlockId183/index.vue';
import CabinetPopUpBlockId184 from '../Popups/CabinetPopUpBlockId184/index.vue';
import CabinetPopUpBlockId187 from '../Popups/CabinetPopUpBlockId187/index.vue';
import CabinetPopUpBlockId188 from '../Popups/CabinetPopUpBlockId188/index.vue';
import CabinetPopUpBlockId189 from '../Popups/CabinetPopUpBlockId189/index.vue';
import CabinetPopUpBlockId190 from '../Popups/CabinetPopUpBlockId190/index.vue';
import CabinetPopUpBlockId194 from '../Popups/CabinetPopUpBlockId194/index.vue';
import CabinetPopUpBlockId195 from '../Popups/CabinetPopUpBlockId195/index.vue';
import CabinetPopUpBlockId197 from '../Popups/CabinetPopUpBlockId197/index.vue';

import Translate from './i18n';
const { set } = require('../../../../../helpers/cookie');
const benefitsDataLogger = require('../../../../../mixins/benefitsDataLogger');

export default {
  mixins: [benefitsDataLogger, betweenDate, slonDataLogger],
  data() {
    return {
      close: false,
    };
  },
  metaInfo() {
    return {
      bodyAttrs: {
        class: this.show && !this.isWebView ? 'modal-open page-overflow' : '',
      },
    };
  },
  name: 'Discount',
  components: {
    Translate,
    popupBenefitTops60,
    CabinetPopUpBlockId103,
    popupCarOwner,
    popupFrontlineRegions,
    PopupElectricCars,
    PopupTops23,
    PopupTopsC2C,
    PopupTopsSMB,
    PopupTopsB2C1,
    PopupTops28,
    SelectPackages,
    PopupNationalHolidaySellers30,
    PopupNationalHolidaySellers50,
    PopupTopRepublicationSmb,
    PopupTopRepublicationB2c,
    PopupFebruary29,
    PopupFiveCarsWithVIN,
    CabinetPopUpBlockId135,
    CabinetPopUpBlockId136,
    CabinetPopUpBlockId137,
    CabinetPopUpBlockId138,
    CabinetPopUpBlockId139,
    CabinetPopUpBlockId149,
    CabinetPopUpBlockId153,
    CabinetPopUpBlockId154,
    CabinetPopUpBlockId157,
    CabinetPopUpBlockId158,
    CabinetPopUpBlockId161,
    CabinetPopUpBlockId162,
    CabinetPopUpBlockId163,
    CabinetPopUpBlockId164,
    CabinetPopUpBlockId165,
    CabinetPopUpBlockId166,
    CabinetPopUpBlockId171,
    CabinetPopUpBlockId172,
    CabinetPopUpBlockId174,
    CabinetPopUpBlockId177,
    CabinetPopUpBlockId178,
    CabinetPopUpBlockId183,
    CabinetPopUpBlockId184,
    CabinetPopUpBlockId187,
    CabinetPopUpBlockId188,
    CabinetPopUpBlockId189,
    CabinetPopUpBlockId190,
    CabinetPopUpBlockId194,
    CabinetPopUpBlockId195,
    CabinetPopUpBlockId197,
  },
  computed: {
    ...mapGetters({
      GDPR: 'others/GDPR',
      isWebView: 'shared/device/isWebView',
      userData: 'Common/userData',
      advertisements: 'cabinet/advertisements/advertisement/allInfo',
      userHasAdvertisementsForPromo: 'cabinet/userInfo/userHasAdvertisementsForPromo',
      segment: 'cabinet/segment',
      popup: 'infoBlock/cabinet/popupSlideData',
    }),
  },
  methods: {
    onHide() {
      const { name, expires } = this.popup.cookie;

      set(name, 1, { expires });
      this.close = true;
    },
    logOnClick(id, action) {
      this.sendEventToSlonik({
        event_id: id,
        current_point_action: action,
        form_type: 1,
      });
    },
    loggerDiscountPopup(popupData, event) {
      const link = event?.target?.href;
      if (link) {
        this._slonik({
          event_id: 1467,
          current_point_action: popupData.name,
          current_url: link,
          previous_url: this.clearAutoRiaUrl(window?.location?.pathname),
        });
      }
    },
  },
};
