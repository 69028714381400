export default {
	messages: {
		ru: {
			'Проверка VIN-кода по': 'Проверка VIN-кода по',
			'Реестр залогового движемого имущества': 'Реестр залогового движемого имущества',
			'Реестр обременений движимого имущества': 'Реестр обременений движимого имущества',
			'Украинское бюро кредитных историй; КАСКО; ОСАГО': 'Украинское бюро кредитных историй; КАСКО; ОСАГО',
			'Государственный реестр имущественных прав на недвижимое имущество': 'Государственный реестр имущественных прав на недвижимое имущество',
			'МВД Украины': 'МВД Украины',
			'Таможенная база Украины': 'Таможенная база Украины',
			'Полицейская база угнанных автомобилей во Франции, Германии': 'Полицейская база угнанных автомобилей во Франции, Германии',
			'Полицейская база угнанных автомобилей в Венгрии, Италии': 'Полицейская база угнанных автомобилей в Венгрии, Италии',
			'Полицейская база угнанных автомобилей в Литве, Польше, Чехии': 'Полицейская база угнанных автомобилей в Литве, Польше, Чехии',
			'Государственная автомобильная инспекция Российской Федерации': 'Государственная автомобильная инспекция Российской Федерации',
			'База угнанных автомобилей в Норвегии': 'База угнанных автомобилей в Норвегии',
			'База угнанных автомобилей в Румынии, Словении': 'База угнанных автомобилей в Румынии, Словении',
			'База угнанных автомобилей в Швеции, Нидерландах': 'База угнанных автомобилей в Швеции, Нидерландах',
			'Дилерские центры': 'Дилерские центры',
			'СТО при сервисном центре': 'СТО при сервисном центре.',
			'пример отчета': 'пример отчета',
			'Данные об угоне и арестах': 'Данные об угоне и арестах',
			'Кредиты, залоги, аренда': 'Кредиты, залоги, аренда',
			'Проверка истории пробега': 'Проверка истории пробега',
			'Данные о комплектации': 'Данные о комплектации',
			'История сервисного обслуживания': 'История сервисного обслуживания',
			'Ремонты и замена деталей': 'Ремонты и замена деталей',
			'Скачать': 'Скачать',
			'Получить': 'Получить',
			'отчет': 'отчет',
			'Проверка': 'Проверка',
			'проверку': 'проверку',
			'доступна только': 'доступна только',
			'начиная с 1993': 'начиная с 1993 года выпуска',
			'Заказать': 'Заказать',
			'Основные характеристики и данные о комплектации': 'Основные характеристики и данные о комплектации',
			'История всех зафиксированных пробегов и проверка на скручивание': 'История всех зафиксированных пробегов и проверка на скручивание',
			'ДТП в Украине, Европе, США и Южной Корее': 'ДТП в Украине, Европе, США и Южной Корее',
			'Наличие страхового полиса': 'Наличие страхового полиса',
			'История регистраций в Украине, США и Европе': 'История регистраций в Украине, США и Европе',
			'Проверка количества собственников в Украине, США и Европе': 'Проверка количества собственников в Украине, США и Европе',
			'Угоны, аресты и другие обременения': 'Угоны, аресты и другие обременения',
			'Ремонты у официального дилера': 'Ремонты у официального дилера',
			'Сервисное обслуживание': 'Сервисное обслуживание',
			'Реестры МВД Украины': 'Реестры МВД Украины',
			'Официальные базы производителей': 'Официальные базы производителей',
			'Базы официальных дилеров': 'Базы официальных дилеров',
			'Единый государственный веб-портал открытых данных': 'Единый государственный веб-портал открытых данных',
			'Страховые базы: Моторного (транспортного) страхового бюро України (МТСБУ), КАСКО, ОСАГО': 'Страховые базы: Моторного (транспортного) страхового бюро України (МТСБУ), КАСКО, ОСАГО',
			'Украинское бюро кредитных историй': 'Украинское бюро кредитных историй',
			'Реестр залогового движимого имущества': 'Реестр залогового движимого имущества',
			'Государственные европейские реестры': 'Государственные европейские реестры',
			'Полицейские базы угнанных автомобилей стран Европы': 'Полицейские базы угнанных автомобилей стран Европы',
			'База архивных данных Importgenius Ukraine': 'База архивных данных Importgenius Ukraine',
			'Архивные данные с официального аукциона в США: COPART': 'Архивные данные с официального аукциона в США: COPART',
			'Архивные данные с официального аукциона в США: IAAI': 'Архивные данные с официального аукциона в США: IAAI',
			'База архивных данных Manheim': 'База архивных данных Manheim',
			'База данных National Motor Vehicle Title Information System (NMVTIS in USA)': 'База данных National Motor Vehicle Title Information System (NMVTIS in USA)',
			'Базы VINCAR-AUTO и AutoData': 'Базы VINCAR-AUTO и AutoData',
			'Актуальность отчёта': 'Актуальность отчёта',
			'Данные на момент составления отчёта': 'Данные на момент составления отчёта',
			'Данные до момента продажи авто в США и Канаде': 'Данные до момента продажи авто в США и Канаде',
			'Только США': 'Только США',
			'и Канада': 'и Канада',
			'Приклад': 'Пример',
			'звіту': 'отчёта',
			'Вид отчета': 'Вид отчета',
			'': '',
			'Бесплатно': 'Бесплатно',
			'Выбрать проверку VIN-кода': 'Выбрать проверку VIN-кода',
            'проверка пока недоступна': 'Данная проверка пока недоступна для',
			'Буде перевірено VIN-код, який продавець вказав на сторінці авто': 'Будет проверен VIN-код, который продавец указал на странице авто',
			'Зверніть увагу!': 'Обратите внимание!',
			'У вихідні перевірки VIN-коду тривають довше, ніж звичайно. Ваш звіт буде готовий і надісланий вам не пізніше понеділка': 'В выходные проверки VIN-кода занимают больше времени, чем обычно. Ваш отчет будет готов и отправлен вам не позднее понедельника',
			'1 січня перевірки VIN-кодів триватимуть довше, ніж звичайно. Ваш звіт буде готовий і надісланий вам не пізніше 2 січня': '1 января проверки VIN-кодов будут длиться дольше, чем обычно. Ваш отчет будет готов и отправлен вам не позднее 2 января',
		},
		uk: {
			'Проверка VIN-кода по': 'Перевірка VIN-коду по',
			'Реестр залогового движемого имущества': 'Реєстр заставного рухомого майна',
			'Реестр обременений движимого имущества': 'Реєстр обтяжень рухомого майна',
			'Украинское бюро кредитных историй; КАСКО; ОСАГО': 'Українське бюро кредитних історій; КАСКО; ОСАГО',
			'Государственный реестр имущественных прав на недвижимое имущество': 'Державний реєстр майнових прав на нерухоме майно',
			'МВД Украины': 'МВС України',
			'Таможенная база Украины': 'Митна база України',
			'Полицейская база угнанных автомобилей во Франции, Германии': 'Поліцейська база викрадених автомобілів у Франції, Німеччині',
			'Полицейская база угнанных автомобилей в Венгрии, Италии': 'Поліцейська база викрадених автомобілів в Угорщині, Італії',
			'Полицейская база угнанных автомобилей в Литве, Польше, Чехии': 'Поліцейська база викрадених автомобілів в Литві, Польщі, Чехії',
			'Государственная автомобильная инспекция Российской Федерации': 'Державна автомобільна інспекція Російської Федерації',
			'База угнанных автомобилей в Норвегии': 'База викрадених автомобілів в Норвегії',
			'База угнанных автомобилей в Румынии, Словении': 'База викрадених автомобілів в Румунії, Словенії',
			'База угнанных автомобилей в Швеции, Нидерландах': 'База викрадених автомобілів в Швеції, Нідерландах',
			'Дилерские центры': 'Дилерські центри',
			'СТО при сервисном центре': 'СТО при сервісному центрі',
			'пример отчета': 'приклад звіту',
			'Данные об угоне и арестах': 'Данні про викрадення та арешти',
			'Кредиты, залоги, аренда': 'Кредити, застави, оренда',
			'Проверка истории пробега': 'Перевірка історіі пробігу',
			'Данные о комплектации': 'Дані про комплектацію',
			'История сервисного обслуживания': 'Історія сервісного обслуговування',
			'Ремонты и замена деталей': 'Ремонти та заміна деталей',
			'Скачать': 'Завантажити',
			'Получить': 'Отримати',
			'отчет': 'звіт',
			'Проверка': 'Перевірка',
			'проверку': 'перевірку',
			'доступна только': 'доступна тільки',
			'начиная с 1993': 'починаючи з 1993 року випуску',
			'Заказать': 'Замовити',
			'Основные характеристики и данные о комплектации': 'Основні характеристики і дані про комплектацію',
			'История всех зафиксированных пробегов и проверка на скручивание': 'Історія всіх зафіксованих пробігів і перевірка на скручування',
			'ДТП в Украине, Европе, США и Южной Корее': 'ДТП в Україні, Європі, США та Південній Кореї',
			'Наличие страхового полиса': 'Наявність страхового поліса',
			'История регистраций в Украине, США и Европе': 'Історія реєстрацій в Україні, США та Європі',
			'Проверка количества собственников в Украине, США и Европе': 'Перевірка кількості власників в Україні, США та Європі',
			'Угоны, аресты и другие обременения': 'Угони, арешти та інші обтяження',
			'Ремонты у официального дилера': 'Ремонти у офіційного дилера',
			'Сервисное обслуживание': 'Сервісне обслуговування',
			'Реестры МВД Украины': 'Реєстри МВС України',
			'Официальные базы производителей': 'Офіційні бази виробників',
			'Базы официальных дилеров': 'Бази офіційних дилерів',
			'Единый государственный веб-портал открытых данных': 'Єдиний державний веб-портал відкритих даних',
			'Страховые базы: Моторного (транспортного) страхового бюро України (МТСБУ), КАСКО, ОСАГО': 'Страхові бази: Моторного (транспортного) страхового бюро України (МТСБУ), КАСКО, ОСАГО',
			'Украинское бюро кредитных историй': 'Українське бюро кредитних історій',
			'Реестр залогового движимого имущества': 'Реєстр заставного рухомого майна',
			'Государственные европейские реестры': 'Державні європейські реєстри',
			'Полицейские базы угнанных автомобилей стран Европы': 'Поліцейські бази викрадених автомобілів країн Європи',
			'База архивных данных Importgenius Ukraine': ' База архівних даних Importgenius Ukraine',
			'Архивные данные с официального аукциона в США: COPART': 'Архівні дані з офіційного аукціону в США: COPART',
			'Архивные данные с официального аукциона в США: IAAI': 'Архівні дані з офіційного аукціону в США: IAAI',
			'База архивных данных Manheim': 'База архівних даних Manheim',
			'База данных National Motor Vehicle Title Information System (NMVTIS in USA)': 'База даних National Motor Vehicle Title Information System (NMVTIS in USA)',
			'Базы VINCAR-AUTO и AutoData': 'Бази VINCAR-AUTO і AutoData',
			'Актуальность отчёта': 'Актуальність звіту',
			'Данные на момент составления отчёта': 'Дані на момент складання звіту',
			'Данные до момента продажи авто в США и Канаде': 'Дані до моменту продажу авто в США і Канаді',
			'Только США': 'Тільки США',
			'и Канада': 'і Канада',
			'Приклад': 'Приклад',
			'звіту': 'звіту',
			'Вид отчета': 'Вид звіту',
			'': '',
			'Бесплатно': 'Безкоштовно',
			'Выбрать проверку VIN-кода': 'Вибрати перевірку VIN-коду',
			'проверка пока недоступна': 'Дана перевірка поки що недоступна для',
			'Тільки перевірені джерела даних і': 'Тільки перевірені джерела даних і',
			'необмежений час доступу': 'необмежений час доступу',
			'до ваших звітів': 'до ваших звітів',
			'Буде перевірено VIN-код, який продавець вказав на сторінці авто': 'Буде перевірено VIN-код, який продавець вказав на сторінці авто',
			'Зверніть увагу!': 'Зверніть увагу!',
			'У вихідні перевірки VIN-коду тривають довше, ніж звичайно. Ваш звіт буде готовий і надісланий вам не пізніше понеділка': 'У вихідні перевірки VIN-коду тривають довше, ніж звичайно. Ваш звіт буде готовий і надісланий вам не пізніше понеділка',
			'1 січня перевірки VIN-кодів триватимуть довше, ніж звичайно. Ваш звіт буде готовий і надісланий вам не пізніше 2 січня': '1 січня перевірки VIN-кодів триватимуть довше, ніж звичайно. Ваш звіт буде готовий і надісланий вам не пізніше 2 січня',
		},
	}
};
