import { mapActions, mapGetters, mapMutations } from 'vuex';
import betweenDate from '../../mixins/betweenDate';
import slonDataLogger from '../../mixins/slonDataLogger';
import { get } from '../../helpers/cookie';

import { TECH_CHECK_MIN_PRICE, ELECTRIC_FUEL_ID } from '../../../constants/autotest';

const lelekaClient = require('lelekaClient');

export default {
  name: 'CheckCar',
  i18n: require('./i18n').default,
  mixins: [betweenDate, slonDataLogger],
  data() {
    return {
      whenNeeded: 1,
      queryParams: {},
      isShow: false,
      holidayPeriod: false,
    };
  },
  components: {
    'vue-seo': require('./_SEO/index.vue').default,
    'vue-h1': require('./H1/index.vue').default,
    'vue-auto': require('./Auto/index.vue').default,
    'vue-only-for-pro': require('./OnlyForPRO/index.vue').default,
    'vue-vin': require('./VIN/index.vue').default,
    'vue-technical': require('./Technical/index.vue').default,
    'vue-outsite-inspection': require('./OutsiteInspection/index.vue').default,
    'vue-juristical': require('./Juristical/index.vue').default,
    'vue-checking-history': require('./CheckingHistory/index.vue').default,
    'vue-insurance-bases': require('./InsuranceBases/index.vue').default,
    'vue-call-order': require('../CheckCar/CallOrder/index.vue').default,
    'vue-cebia-report': require('./CebiaReport/index.vue').default,
    'vue-verification-center': require('./VerificationCenter/index.vue').default,
    'vue-manager': require('../CheckCar/Manager/index.vue').default,
  },
  computed: {
    ...mapGetters({
      getIsSetByAutoId: 'others/autotest/isSetByAutoId',
      getUSAreports: 'others/autotest/hasUSAReport',
      advertisements: 'graphql/advertisements',
      getBidfax: 'others/autotest/getBidfax',
      checkedEventsCount: 'others/insurance/getCheckedEventsCount',
      getReportId: 'others/insurance/getReportId',
      userData: 'Common/userData',
      autotestLoggerData: 'others/autotest/autotestLoggerData',
      loggerDefaultData: 'others/autotest/autotestLoggerDefaultData',
      cebiaReport: 'others/autotest/cebiaReportLink',
    }),
    userId() {
      const { userData: { userId } = {} } = this;
      return userId;
    },
    checkedInsuranceEvents() {
		    return this.checkedEventsCount > 0;
    },
    reportId() {
		    return this.getReportId;
    },
    bidfaxLink() {
      return this.getBidfax.link;
    },
    hasUSAReport() {
      return this.getUSAreports || this.bidfaxLink;
    },
    advertisementId() {
      return this.$route.params.advertisementId;
    },
    advertisementsData() {
      return this.advertisements(this.advertisementId);
    },
    availableChecks() {
      return this.getIsSetByAutoId(this.advertisementId) || {};
    },
    // залишаємо варіант, який переміг в А/B тесті для машинок до 3500$ (тех. переревірка на 1-ому місці)
    techCheck3500() {
      return this.advertisementsData?.price?.main?.value <= TECH_CHECK_MIN_PRICE;
    },
    isElectric() {
      return Number(this.advertisementsData?.fuel?.id) === ELECTRIC_FUEL_ID;
    },
    isVinnytsiaState() {
      const stateId = Number(this.advertisementsData?.location?.state?.id);
      return stateId === 1;
    },
    cebiaReportLink() {
      return this.cebiaReport?.link;
    },
    isInTimeInterval() {
      const currentTime = new Date();
      const dayOfWeek = currentTime.getDay();
      const getHours = currentTime.getHours();
      return (
          (dayOfWeek === 6 && getHours >= 18) ||
          (dayOfWeek === 0) ||
          (dayOfWeek === 1 && getHours <= 5)
      );
    },
    forHolidayPeriod() {
      const startDate = new Date('Tue Dec 31 2024 20:00:00 GMT+0200 (Eastern European Standard Time)');
      const endDate = new Date('Thu Jan 02 2025 08:00:00 GMT+0200 (Eastern European Standard Time)');
      return ((new Date() >= startDate) && (endDate >= new Date()))
    },
  },
  watch: {
    autotestLoggerData(logData) {
      if (Object.keys(logData).length) {
        this.autotestLogger(logData);
      }
    },
  },
  methods: {
    ...mapActions({
      isSetByAutoId: 'others/autotest/isSetByAutoId',
      fetchAdvertisements: 'graphql/advertisements',
      fetchBidfax: 'others/autotest/fetchBidfax',
      fetchCheckedEventsCount: 'others/insurance/fetchCheckedEventsCount',
      fetchCebiaReportLink: 'others/autotest/fetchCebiaReportLink',
    }),
    ...mapMutations({
      autotestLoggerDefaultData: 'others/autotest/autotestLoggerDefaultData',
    }),
    init() {
      const promises = this.advertisementId
        ? Promise.all([
          this.isSetByAutoId(this.advertisementId).catch((e) => console.error('getting isSetByAutoId error: ', e)),
          this.fetchAdvertisements([this.advertisementId]).catch(console.error),
          this.fetchBidfax(this.advertisementId).catch(console.error),
          this.fetchCheckedEventsCount(this.advertisementId).catch(console.error),
          this.fetchCebiaReportLink(this.advertisementId).catch(console.error),
        ])
        : Promise.reject('no adv id');

      return promises;
    },
    autotestLogger(logData) {
      try {
        this._slonik({ ...this.loggerDefaultData, ...logData });
      } catch (e) {
        console.error('===ERROR in autotestLogger===', e.toString());
      }
    },
    initAutotestLogger() {
      try {
        const defaultLogData = { event_id: 1057 };
        if (this.advertisementId) defaultLogData.proposal_id = this.advertisementId;
        if (document?.referrer) defaultLogData.previous_url = encodeURI(this.clearAutoRiaUrl(document.referrer));
        this.autotestLoggerDefaultData(defaultLogData);
        this.autotestLogger({ request_type: 1 });
      } catch (e) {
        console.error('===ERROR in initAutotestLogger===', e.toString());
      }
    },
    vinOrderingLogs() {
      this._slonik({
        event_id: 1425,
        click_action: 1,
      });
    },
  },
  init({ store, context, route }) {
    if (context) {
      const cookies = { Cookie: context.request.headers.cookie || {} };
      const autoId = Number(route.to.params.advertisementId);

      const actionsPromises = [
        store.dispatch('others/autotest/autotestVinCheckPrices', { cookies, autoId }),
      ];

      if (Number.isInteger(autoId) && autoId > 0) {
        actionsPromises.push(
          store.dispatch('others/autotest/isSetByAutoId', autoId),
          store.dispatch('others/vin/vinSvg', { autoId, fontSize: 16 }),
          store.dispatch('others/autotest/usaReportsExist', { autoId, cookies }),
        );
      }
      return Promise.all(actionsPromises);
    }
  },
  serverPrefetch() {
    return this.init();
  },
  mounted() {
    lelekaClient();
    this.init()
      .then(() => {
        window.riaHistoryStorage.addHistoryObject(22, Number(this.advertisementId));
      }).catch((errorInit) => {
        console.error(':[path vue/components/CheckCar_v2/index.js]:[method mounted]:[hash 20201113164832]', errorInit.message);
      });
    this.queryParams = this.$route.query;
    this.isShow = this.isInTimeInterval;
    this.holidayPeriod = this.forHolidayPeriod;
    setTimeout(this.initAutotestLogger, 1000);
  },
};
